import * as React from "react";
import { graphql } from 'gatsby';
import { Layout } from "../Layout";
import { Description } from "../Description";
import { InfoBox } from "../InfoBox";
import { PageTitle } from "../PageTitle";
import { Gallery } from "../Gallery";
import { TopBox, MainImageContainer } from "../TopBox";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { GoogleMap } from "../Map";
import { lighthouseNameToUrl } from "../../lib/lighthouses";

export const Head = ({ data }) => {
  const metadata = data.lighthouse.frontmatter;
  const description = `${metadata.name} Lighthouse, first established in ${metadata.yearEstablished}, is a lighthouse that is ${metadata.heightInFeet} feet tall and operated by ${metadata.operator}. ${metadata.designer && `Designed by ${metadata.designer}`}`;

  return (
    <>
      <title>{metadata.name} Lighthouse | Worldwide Lighthouses</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={metadata.name} />
      <meta property="og:site_name" content="Worldwide Lighthouses" />
      <meta property="og:url" content="https://worldwidelighthouses.com" />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="place" />
      {
        data.images.edges
          .map(edge => getImage(edge.node).images)
          .map(image =>
            <meta
              property="og:image"
              content={image.fallback.src}
            />
          )
      }
    </>
  )
}

const Lighthouse = ({ data }) => {
  const lighthouse = data.lighthouse;
  const metadata = data.lighthouse.frontmatter;

  return (
    <Layout>
      <PageTitle title={metadata.name} />
      <TopBox>
        <MainImageContainer>
          {data.images?.edges?.[0]?.node &&
            <GatsbyImage
              image={getImage(data.images.edges[0].node)}
              alt={`${metadata.name} Lighthouse`}
              style={{
                borderRadius: "8px",
                marginTop: "10px",
                border: "3px solid #1E1E1E",
                width: "100%"
              }}
              imgStyle={{
                borderRadius: "5px"
              }}
            />
          }
        </MainImageContainer>
        <InfoBox
          name={metadata.name}
          yearEstablished={metadata.yearEstablished}
          yearCurrentLighthouseBuilt={metadata.yearCurrentLighthouseBuilt}
          yearAutomated={metadata.yearAutomated}
          yearElectrified={metadata.yearElectrified}
          heightInFeet={metadata.heightInFeet}
          designer={metadata.designer}
          operator={metadata.operator}
        />
      </TopBox>
      <Description>
        <div dangerouslySetInnerHTML={{ __html: lighthouse.html }} />
      </Description>
      {data.images?.edges && data.images?.edges.length > 0 &&
        <Gallery>
          {data.images.edges.slice(1).map(edge =>
            <GatsbyImage
              style={{ borderRadius: "8px", border: "3px solid #1E1E1E", width: "100%" }}
              image={getImage(edge.node)}
              alt={`${metadata.name} Lighthouse`}
              imgStyle={{ borderRadius: "5px" }}
            />
          )}

          {lighthouse.frontmatter.youtube && lighthouse.frontmatter.youtube.map(ytLink =>
            <iframe
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              src={ytLink}
              style={{ width: "100%", height: "99%", minHeight:"400px", borderRadius: "8px", border: "3px solid #1E1E1E" }}
            />)
          }
        </Gallery>
      }
      {
        lighthouse.frontmatter.lat && lighthouse.frontmatter.lng && <GoogleMap googleMapsApiKey="AIzaSyBI3WITyvF4JEOEjTYFpnVg6MqODmeOgoU" lighthousePins={[{
          lat: lighthouse.frontmatter.lat,
          lng: lighthouse.frontmatter.lng,
          url: lighthouseNameToUrl(lighthouse.frontmatter.name),
          name: lighthouse.frontmatter.name
        }]} defaultCenter={{
          lat: lighthouse.frontmatter.lat,
          lng: lighthouse.frontmatter.lng,
        }} zoom={8} />
      }
    </Layout>
  );
};

export default Lighthouse;

export const Query = graphql`
  query LighthouseQuery($id: String!, $urlEncodedName: String!) {
    lighthouse: markdownRemark(id: { eq: $id }) {
      html,
      frontmatter {
        name,
        yearEstablished,
        yearCurrentLighthouseBuilt,
        yearAutomated,
        yearElectrified,
        heightInFeet,
        designer,
        operator,
        youtube,
        lat,
        lng
      }
    }
    images: allFile(
      filter: {
        relativeDirectory: { eq: $urlEncodedName }
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
      },
      sort: { order:ASC fields: absolutePath }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 530
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
